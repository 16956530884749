import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakpoints, colors } from '../styles/config';
import { SocialMediaDisplay } from './SocialMediaDisplay';
import { constructPath } from '../i18n/util';
import { useI18n } from '../i18n/useI18n';
import Text from './typography/Text';

const YEAR = new Date().getFullYear();

const FooterContainer = styled.footer`
    display: block;
    padding: 1rem;
    @media (min-width: ${breakpoints.tablet}px) {
        padding: 1rem 2rem;
    }
    @media (min-width: ${breakpoints.laptop}px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const CopyrightLine = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5em;
    color: ${colors.grey60};
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (min-width: ${breakpoints.tablet}px) {
        padding: 0 2rem 1rem;
    }
`;

const SocialMediaContainer = styled.div`
    @media (max-width: ${breakpoints.laptop - 0.02}px) {
        margin-left: -4px;
    }
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;

    @media (min-width: ${breakpoints.tablet}px) {
        flex-direction: row;
        align-items: center;
    }

    @media (min-width: ${breakpoints.laptop}px) {
        margin-bottom: 0;
    }
`;

const StyledLink = styled(Link)`
    font-size: 12px;
    line-height: 32px;
    font-weight: 300;
    text-decoration: none;

    @media (max-width: ${breakpoints.tablet - 0.02}px) {
        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    &,
    &:hover,
    &:focus,
    &:visited {
        color: ${colors.grey60};
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
`;

interface FooterNavigationElementProps {
    to: string;
    text: string;
}

function FooterNavigationElement({ text, to }: FooterNavigationElementProps): JSX.Element {
    return <StyledLink to={to}>{text}</StyledLink>;
}

export interface FooterProps {}

export default function Footer(): JSX.Element {
    const { language, t } = useI18n();
    const routes: FooterNavigationElementProps[] = [
        { to: constructPath('about', language), text: t('nav.about-me') },
        { to: constructPath('plastic-surgery', language), text: t('nav.plastic-surgery') },
        // { to: constructPath('faq', language), text: t('nav.faq') },
        { to: constructPath('reviews', language), text: t('nav.reviews') },
        { to: constructPath('directions', language), text: t('nav.directions') },
        { to: constructPath('pricing', language), text: t('nav.pricing') },
        { to: constructPath('index', language) + '#' + t('nav.contact').toLowerCase(), text: t('nav.contact') },
    ];
    const navigation = routes.map(route => <FooterNavigationElement key={route.to} {...route} />);

    const privacyPolicyLink = constructPath('privacy-policy', language);

    return (
        <>
            <FooterContainer>
                <NavigationContainer>{navigation}</NavigationContainer>
                <SocialMediaContainer>
                    <SocialMediaDisplay />
                </SocialMediaContainer>
            </FooterContainer>
            <CopyrightLine as={Text}>
                <span>&copy; IPL Marta Wilczyńska-Staniul, {YEAR}</span>
                <Link to={privacyPolicyLink}>{t('nav.privacyPolicy')}</Link>
            </CopyrightLine>
        </>
    );
}
