// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-directions-tsx": () => import("./../../../src/pages/directions.tsx" /* webpackChunkName: "component---src-pages-directions-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-templates-plastic-surgery-article-page-tsx": () => import("./../../../src/templates/PlasticSurgeryArticlePage.tsx" /* webpackChunkName: "component---src-templates-plastic-surgery-article-page-tsx" */),
  "component---src-templates-plastic-surgery-page-tsx": () => import("./../../../src/templates/PlasticSurgeryPage.tsx" /* webpackChunkName: "component---src-templates-plastic-surgery-page-tsx" */)
}

