import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import browserLang from 'browser-lang';
import { withPrefix, WrapPageElementBrowserArgs } from 'gatsby';
import i18next, { i18n } from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { defaultLanguage, languages } from './i18n/util';
import Layout from './components/Layout';
import { I18nContext, I18nContextType } from './i18n/context';

export interface PageContext {
    i18n: I18nContextType;
}

interface WrapperProps {
    location: any;
    i18next: i18n;
    context: I18nContextType;
}

const PREFERRED_LANGUAGE_KEY = 'preferredLanguage';

const Wrapper: React.FC<WrapperProps> = ({ children, i18next, context, location }) => {
    useEffect(() => {
        if (i18next.language !== context.language) {
            i18next.changeLanguage(context.language);
        }
    }, [context.language]);

    return (
        <I18nextProvider i18n={i18next}>
            <I18nContext.Provider value={context}>
                <RecoilRoot>
                    <Layout location={location} language={context.language}>
                        {children}
                    </Layout>
                </RecoilRoot>
            </I18nContext.Provider>
        </I18nextProvider>
    );
};

export function wrapPageElement({ element, props }: WrapPageElementBrowserArgs<any, PageContext>) {
    const location = props.location;
    const { resources, language } = props.pageContext.i18n;

    if (
        location.pathname === '/' &&
        typeof window !== 'undefined' &&
        !window.localStorage.getItem(PREFERRED_LANGUAGE_KEY)
    ) {
        const savedLanguage = window.localStorage.getItem(PREFERRED_LANGUAGE_KEY);
        const detected =
            savedLanguage ??
            browserLang({
                languages: languages,
                fallback: defaultLanguage,
            });

        if (detected !== language) {
            window.localStorage.setItem(PREFERRED_LANGUAGE_KEY, detected);
            const queryParams = location.search || '';
            const newUrl = withPrefix(`/${detected}${location.pathname}${queryParams}${location.hash}`);
            window.location.replace(newUrl);
            return null;
        }
    }

    if (typeof window !== 'undefined') {
        window.localStorage.setItem(PREFERRED_LANGUAGE_KEY, language);
    }

    if (!i18next.isInitialized) {
        i18next.use(initReactI18next).init({
            defaultNS: 'common',
            resources: resources,
            lng: language,
            fallbackLng: defaultLanguage,
            keySeparator: '.',
            nsSeparator: ':',
            interpolation: {
                escapeValue: false,
            },
        });
    }

    Object.keys(resources[language]).map(ns => {
        if (!i18next.hasResourceBundle(language, ns)) {
            i18next.addResourceBundle(language, ns, resources[language][ns]);
        }
    });

    const context: I18nContextType = props.pageContext.i18n;

    return (
        <Wrapper location={location} i18next={i18next} context={context}>
            {element}
        </Wrapper>
    );
}
