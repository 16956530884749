import { useEffect } from 'react';

export function useKeydown(fn: (e: KeyboardEvent) => void, key: string | null = null, props: any[] = []): void {
    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (!key || e.key === key) fn(e);
        };
        window.addEventListener('keydown', listener);
        return () => {
            window.removeEventListener('keydown', listener);
        };
    }, props);
}
