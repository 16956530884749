import React from 'react';
import CollapsibleNavigation from './CollapsibleNavigation';
import DesktopNavigation from './DesktopNavigation';

const Navigation: React.FC = () => {
    return (
        <>
            <DesktopNavigation />
            <CollapsibleNavigation />
        </>
    );
};

export default Navigation;
