import React from 'react';
import { useI18n } from '../i18n/useI18n';
import styled from 'styled-components';

const FocusableVisuallyHiddenLink = styled.a`
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;

    &:focus {
        position: static;
        overflow: visible;
        clip: auto;
        width: auto;
        height: auto;
        outline: none;
    }
`;

export function SkipToContent(): JSX.Element {
    const { t } = useI18n();
    return <FocusableVisuallyHiddenLink href="#main-content">{t('skip-to-content')}</FocusableVisuallyHiddenLink>;
}
