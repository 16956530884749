const pages = require('../routes.json');

const DEFAULT_LANGUAGE = 'pl';
const LANGUAGES = ['pl', 'en'];

function findPageByPath(path) {
    return pages.find(p => p.path.pl === path || p.path.en === path);
}

function findPageByName(name) {
    const page = pages.find(p => p.name === name);
    if (!page) {
        throw new Error(`Missing page with name '${name}' in routes.json!`);
    }
    return page;
}

function constructPath(name, language = DEFAULT_LANGUAGE) {
    const path = findPageByName(name);
    const prefix = language === DEFAULT_LANGUAGE ? '' : `${language}/`;
    return `/${prefix}${path.path[language]}`;
}

function generateLanguageUrls(name) {
    return LANGUAGES.reduce((result, lang) => {
        result[lang] = constructPath(name, lang);
        return result;
    }, {});
}

function languagePrefix(language) {
    return language === DEFAULT_LANGUAGE ? '' : `/${language}`;
}

function localizePath(language, path, queryOrFragment = '', fragment = '') {
    const slash = path.startsWith('/') ? '' : '/';
    const prefix = languagePrefix(language);
    return `${prefix}${slash}${path}${queryOrFragment}${fragment}`;
}

module.exports = {
    findPageByName,
    findPageByPath,
    constructPath,
    generateLanguageUrls,
    defaultLanguage: DEFAULT_LANGUAGE,
    languages: LANGUAGES,
    languagePrefix,
    localizePath,
};
