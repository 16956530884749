import React from 'react';
import Navigation from './navigation/Navigation';
import Logo from './logo/Logo';
import styled from 'styled-components';
import { colors, desktop, tablet } from '../styles/config';

const headerSizes = {
    mobile: 48,
    tablet: 60,
    desktop: 80,
};

const HeaderBanner = styled.header`
    display: flex;
    width: 100%;
    background: ${colors.grey80};
    color: ${colors.white};
    height: ${headerSizes.mobile}px;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    position: relative;
    z-index: 100;

    ${tablet`
        height: ${headerSizes.tablet}px;
        justify-content: flex-start;
  `};
    ${desktop`
        padding-left: 2rem;
        height: ${headerSizes.desktop}px;
        margin: 2rem;
        width: calc(100% - 4rem);
  `};
`;

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
    return (
        <HeaderBanner>
            <Logo />
            <Navigation />
        </HeaderBanner>
    );
};

export default Header;
