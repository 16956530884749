import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { constructPath } from './util';
import { useI18n } from './useI18n';

export interface I18nLinkProps {
    name: string;
    language?: string;
    fragment?: string;
}

export type LocalizedLinkProps = Omit<GatsbyLinkProps<any>, 'to'> & I18nLinkProps;
export const Link: React.FC<LocalizedLinkProps> = ({ children, name, language, fragment, ...rest }) => {
    const { i18n } = useI18n();
    if (!language) language = i18n.language;
    const urlFragment: string = fragment ? `#${fragment}` : '';
    const to: string = constructPath(name, language) + urlFragment;
    return (
        // @ts-ignore
        <GatsbyLink {...rest} to={to}>
            {children}
        </GatsbyLink>
    );
};

export default Link;
