import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/config';
import { Link } from 'gatsby';

const NavigationLink = styled(Link)`
    display: flex;
    width: 100%;
    font-size: 14px;
    line-height: 3rem;
    height: 3rem;
    text-transform: uppercase;
    margin: 0;
    padding: 0 1rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${colors.white};
    background: transparent;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
        color: ${colors.white};
        background: ${colors.blue};
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px ${colors.outline};
    }

    &.is-active:not(:focus):not(:hover) {
        background: rgba(255, 255, 255, 0.05);
    }
`;

export default NavigationLink;
