import styled from 'styled-components';

export interface ContainerProps {
    isBig?: boolean;
}

export const SocialMediaDisplayContainer = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: ${p => (p.isBig ? 32 : 22)}px;
`;
