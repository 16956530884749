export type Initial = 'Initial';
export type Accepted = 'Accepted';
export type Declined = 'Declined';
export type CookieAcceptanceState = Initial | Accepted | Declined;

export const ACCEPTED: Accepted = 'Accepted';
export const INITIAL: Initial = 'Initial';
export const DECLINED: Declined = 'Declined';

const COOKIE_CONSENT = 'cookie_consent';

let acceptanceState: CookieAcceptanceState = 'Initial';
const callbacks: Array<ConsentCallback> = [];

// set initial state (can be done only in browser so we check for localStorage presence)
if (typeof localStorage !== 'undefined') {
    const state = localStorage.getItem(COOKIE_CONSENT);
    if (state === ACCEPTED || state === DECLINED) {
        confirmCookiesConsent(state);
    }
}

export interface ConsentCallback {
    (acceptedUseOfCookies: CookieAcceptanceState): void;
}

function safeCallback(fn: ConsentCallback, value: CookieAcceptanceState): () => void {
    return () => {
        try {
            fn.call(null, value);
        } catch (e) {
            setTimeout(() => {
                throw e;
            });
        }
    };
}

export function confirmCookiesConsent(userAcceptedCookies: Accepted | Declined) {
    const reload = acceptanceState !== INITIAL;
    acceptanceState = userAcceptedCookies;
    localStorage.setItem(COOKIE_CONSENT, acceptanceState);

    while (callbacks.length) {
        const fn = callbacks.shift()!;
        safeCallback(fn, acceptanceState)();
    }

    if (reload) location.reload();
}

export function registerConsentConfirmation(fn: ConsentCallback): void {
    if (acceptanceState !== INITIAL) setTimeout(safeCallback(fn, acceptanceState));
    else callbacks.push(fn);
}

export function haveInteractedWithConsent(): boolean {
    return acceptanceState !== INITIAL;
}
