import { css } from 'styled-components';

export const colors = {
    black: '#000000',
    grey80: '#333333',
    grey70: '#4d4d4d',
    grey60: '#676767',
    grey40: '#989898',
    grey20: '#CCCCCC',
    grey13: '#DDDDDD',
    grey6: '#EEEEEE',
    grey2: '#FAFAFA',
    white: '#ffffff',
    blue: '#0079DB',
    blueDarker: '#0066ba',
    outline: `rgba(0, 121, 219, 0.6)`,
    outlineDarker: `rgba(0, 102, 186, 0.6)`,
    outlineGrey60: `rgba(103, 103, 103, 0.6)`,
    red: '#F14668',
    green: '#28a745',
};

export const breakpoints = {
    phone: 0,
    tablet: 768,
    laptop: 1024,
    desktop: 1366,
};

export const fonts = {
    lato: 'Lato',
    workSans: 'Work Sans',
};

export const mediaUp = (breakpoint: number) => (first: any, ...rest: any[]) => css`
    @media (min-width: ${breakpoint}px) {
        ${css(first, ...rest)}
    }
`;

export const mediaDown = (breakpoint: number) => (first: any, ...rest: any[]) => css`
    @media (max-width: ${breakpoint - 0.02}px) {
        ${css(first, ...rest)}
    }
`;

export const print = (first: any, ...args: any[]) => css`
    @media print {
        ${css(first, ...args)}
    }
`;

export const mobile = mediaDown(breakpoints.tablet);
export const tablet = mediaUp(breakpoints.tablet);
export const touch = mediaDown(breakpoints.desktop);
export const desktop = mediaUp(breakpoints.desktop);
