import React from 'react';
import { Helmet } from 'react-helmet';
import { useI18n } from './useI18n';

export interface AlternatePathProps {
    siteUrl: string;
}

export function AlternatePath({ siteUrl }: AlternatePathProps): JSX.Element {
    const { language, languageUrls, languages } = useI18n();
    const anotherLanguages = languages.filter(l => l !== language);

    return (
        <Helmet>
            {anotherLanguages.map(language => (
                <link key={language} rel="alternate" href={siteUrl + languageUrls[language]} hrefLang={language} />
            ))}
        </Helmet>
    );
}
