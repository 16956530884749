import React, { Component } from 'react';
import Overlay from './Overlay';
import TrapNode from './TrapNode';
import canUseDom from '../util/canUseDom';

export interface ModalProps {
    'aria-label'?: string;
    'aria-labelledby'?: string;
    'aria-describedby'?: string;
    role?: 'dialog' | 'alertdialog';
}

export interface ModalState {
    wrapperBlurred: boolean;
}

export default class Modal extends Component<ModalProps, ModalState> {
    static modalCount = 0;

    static defaultProps: Partial<ModalProps> = {
        role: 'dialog',
    };

    static getRootElement(): HTMLElement {
        return document.getElementById('___gatsby')!;
    }

    private readonly wrapper: React.RefObject<HTMLDivElement>;
    private readonly previouslyFocusedElement: Element | null = null;
    private lastFocusedElement: Element | null = null;
    private ignoreDocumentFocusListener = false;

    constructor(props: ModalProps) {
        super(props);
        this.state = { wrapperBlurred: false };
        this.wrapper = React.createRef();
        this.previouslyFocusedElement = document.activeElement;
    }

    componentDidMount() {
        document.addEventListener('focus', this.onDocumentFocusChange, false);
        this.wrapper.current?.focus();
        if (Modal.modalCount === 0) {
            document.documentElement.style.overflow = 'hidden';
            Modal.getRootElement().setAttribute('aria-hidden', 'true');
        }
        Modal.modalCount++;
    }

    componentWillUnmount() {
        document.removeEventListener('focus', this.onDocumentFocusChange, false);
        this.tryFocus(this.previouslyFocusedElement);
        Modal.modalCount--;
        if (Modal.modalCount === 0) {
            document.documentElement.style.overflow = 'auto';
            Modal.getRootElement().removeAttribute('aria-hidden');
        }
    }

    render() {
        if (!canUseDom()) return null;

        return (
            <Overlay>
                <TrapNode onFocus={this.focusLastChild} />
                <div
                    role={this.props.role}
                    ref={this.wrapper}
                    aria-label={this.props['aria-label']}
                    aria-labelledby={this.props['aria-labelledby']}
                    aria-describedby={this.props['aria-describedby']}
                    tabIndex={this.state.wrapperBlurred ? undefined : -1}
                    onBlur={this.onWrapperBlur}
                    style={{ position: 'fixed', inset: '0' }}
                >
                    {this.props.children}
                </div>
                <TrapNode onFocus={this.focusFirstChild} />
            </Overlay>
        );
    }

    private focusFirstChild = () => {
        if (!this.wrapper.current) return;
        this.focusFirstNode(this.wrapper.current);
        if (document.activeElement === this.lastFocusedElement) this.focusLastChild();
        this.lastFocusedElement = document.activeElement;
    };

    private focusLastChild = () => {
        if (!this.wrapper.current) return;
        this.focusLastNode(this.wrapper.current);
        this.lastFocusedElement = document.activeElement;
    };

    private focusFirstNode(node: Element): boolean {
        if (this.tryFocus(node)) return true;
        const len = node.children.length;
        for (let i = 0; i < len; i++) if (this.focusFirstNode(node.children[i])) return true;
        return false;
    }

    private focusLastNode(node: Element): boolean {
        if (this.tryFocus(node)) return true;
        for (let i = node.children.length - 1; i >= 0; i--) if (this.focusLastNode(node.children[i])) return true;
        return false;
    }

    private tryFocus(node: any): boolean {
        this.ignoreDocumentFocusListener = true;

        try {
            if (node && node.focus && typeof node.focus === 'function') node.focus();
        } catch (e) {}

        this.ignoreDocumentFocusListener = false;

        return document.activeElement === node;
    }

    private onDocumentFocusChange = () => {
        if (
            this.ignoreDocumentFocusListener ||
            !this.wrapper.current ||
            this.wrapper.current.contains(document.activeElement)
        )
            return;
        this.focusFirstChild();
    };

    private onWrapperBlur = () => {
        this.setState({ wrapperBlurred: true });
    };
}
