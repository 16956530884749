import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { useI18n } from '../../i18n/useI18n';
import styled, { css } from 'styled-components';
import { IconButton } from '../Button';
import { colors } from '../../styles/config';
import { animated, useTransition } from 'react-spring';

export type NotificationType = 'success' | 'error';

export interface INotification {
    readonly id: number;
    readonly type: NotificationType;
    readonly message: string;
}

const notificationState = atom<INotification[]>({
    key: 'notification',
    default: [],
});

let NOTIFICATION_UNIQUE_ID = 0;

function useAddNotification() {
    const setNotifications = useSetRecoilState(notificationState);
    return useCallback((type: NotificationType, message: string) => {
        setNotifications(ns => [...ns, { type, message, id: NOTIFICATION_UNIQUE_ID++ }]);
    }, []);
}

function useRemoveNotification(): (id: number) => void {
    const setNotifications = useSetRecoilState(notificationState);
    return useCallback((id: number) => {
        setNotifications(ns => ns.filter(n => n.id !== id));
    }, []);
}

const StyledNotification = styled(animated.div)<{ type: NotificationType }>`
    width: 400px;
    max-width: calc(100% - 2rem);
    padding: 1rem;
    margin: 0 0 1rem 1rem;

    ${p =>
        p.type === 'error' &&
        css`
            background: ${colors.red};
            color: ${colors.white};
        `};

    ${p =>
        p.type === 'success' &&
        css`
            background: ${colors.green};
            color: ${colors.white};
        `};

    p {
        margin-bottom: 0.75rem;
        line-height: 1.3em;
    }
`;

const StyledNotificationDismissButton = styled(IconButton)`
    font-size: 12px;
    color: ${colors.white};
`;

function NotificationComponent({ type, message, id, style }: INotification & { style: any }) {
    const { t } = useI18n();
    const removeNotification = useRemoveNotification();
    const remove = useCallback(() => removeNotification(id), []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            remove();
        }, 15000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <StyledNotification style={style} type={type}>
            <p>{message}</p>
            <StyledNotificationDismissButton onClick={remove}>
                {t('notification.dismiss')}
            </StyledNotificationDismissButton>
        </StyledNotification>
    );
}

const StyledNotificationsContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: auto;
`;

export function NotificationContainer() {
    const notifications = useRecoilValue(notificationState);
    const transitions = useTransition(notifications, n => n.id, {
        from: { transform: 'translate3d(-30px, 0, 0)', opacity: 0 },
        enter: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
        leave: { transform: 'translate3d(30px, 0, 0)', opacity: 0 },
    });

    if (typeof window === 'undefined') return null;

    const nodes = transitions.map(({ item, key, props }) => (
        <NotificationComponent key={key} style={props} id={item.id} type={item.type} message={item.message} />
    ));
    return ReactDOM.createPortal(
        <StyledNotificationsContainer>{nodes}</StyledNotificationsContainer>,
        window.document.body,
        'notification-container'
    );
}

export default useAddNotification;
