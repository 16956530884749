import React from 'react';

export type Resources = Record<string, Record<string, Record<string, string>>>;
export interface I18nContextType {
    currentPath: string;
    currentName: string;
    language: string;
    languages: string[];
    resources: Resources;
    languageUrls: Record<string, string>;
    defaultLanguage: string;
    isDefaultLanguage: boolean;
}

export const I18nContext = React.createContext<I18nContextType>({
    currentPath: '',
    currentName: '',
    language: '',
    languages: [],
    resources: {},
    languageUrls: {},
    defaultLanguage: '',
    isDefaultLanguage: false,
});
