import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { useI18n } from '../../i18n/useI18n';
import { constructPath } from '../../i18n/util';
import { colors, tablet } from '../../styles/config';
import { desktopNavigationElements as ELEMENTS } from './NavigationElement';

const DesktopNavigationContainer = styled.nav`
    height: 100%;
    display: none;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: 1rem;
    ${tablet`
        display: flex;
    `}
`;

const DesktopNavigationElement = styled(Link).attrs({
    partiallyActive: true,
    activeClassName: 'is-active',
})`
    color: ${colors.white};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    will-change: border-color;
    transition: border-bottom-color 0.6s cubic-bezier(0.25, 1, 0.5, 1);

    &:focus,
    &:hover {
        border-bottom-color: ${colors.grey60};
    }
    &.is-active {
        border-bottom-color: ${colors.blue};
        &:hover,
        &:focus {
            border-bottom-color: ${colors.blueDarker};
        }
    }
    &:not(:first-child),
    &:not(:last-child) {
        margin: 0 1rem;
    }
`;

const DesktopNavigation: React.FC = () => {
    const { t, language } = useI18n();
    const elements = ELEMENTS.map(({ translation, name, translationFragment }) => {
        /* i18next-extract-disable-next-line */
        const fragment = translationFragment ? '#' + t(translationFragment) : '';
        const to = constructPath(name, language) + fragment.toLowerCase();
        return (
            <DesktopNavigationElement key={name} to={to}>
                {t(translation) /* i18next-extract-disable-line */}
            </DesktopNavigationElement>
        );
    });
    return <DesktopNavigationContainer>{elements}</DesktopNavigationContainer>;
};

export default DesktopNavigation;
