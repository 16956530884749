import React from 'react';
import IconLink from '../IconLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import SrOnly from '../SrOnly';
import iconForType from './iconForType';
import { ISocialMediaType } from '../../models/ISocialMedia';

export interface SocialMediaLinkProps {
    type: ISocialMediaType;
    url: string;
    text: string;
}

const SocialMediaIconLink = styled(IconLink)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
`;

export const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({ type, url, text }) => {
    const icon = iconForType(type);
    return (
        <SocialMediaIconLink href={url} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={icon} fixedWidth={true} />
            <SrOnly>{text}</SrOnly>
        </SocialMediaIconLink>
    );
};
