import { useEffect, useRef, useState } from 'react';
import sanitizeHTML from './sanitize';

export default function useSanitizedHtml(content: string): string {
    const previousContent = useRef(null);
    const [safeContent, setSafeContent] = useState(() => '');

    useEffect(() => {
        if (previousContent.current !== content) {
            setSafeContent(sanitizeHTML(content));
        }
    }, [content]);

    return safeContent;
}
