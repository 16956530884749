import styled, { css } from 'styled-components';
import { colors } from '../styles/config';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export type ButtonVariant = 'primary' | 'secondary';
export interface ButtonProps {
    fixedWidth?: boolean;
    variant?: ButtonVariant;
}

export const Button = styled.button<ButtonProps>`
    margin: 0;
    padding: 0 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    text-decoration: none;
    height: 3rem;

    ${p =>
        p.fixedWidth &&
        css`
            width: 275px;
            max-width: 100%;
        `};

    ${p =>
        p.variant === 'primary' &&
        css`
            color: ${colors.white};
            background: ${colors.blue};

            &:hover,
            &:focus:not(:active) {
                color: ${colors.white};
                background: ${colors.blueDarker};
            }
        `};

    ${p =>
        p.variant === 'secondary' &&
        css`
            color: ${colors.white};
            background: ${colors.grey40};

            &:hover,
            &:focus:not(:active) {
                color: ${colors.white};
                background: ${colors.grey60};
            }
        `}

    &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
    }
`;

Button.defaultProps = {
    variant: 'primary',
    type: 'button',
};

export type StyledButtonWithArrow<T> = ButtonProps & { children?: React.ReactNode; loading?: boolean } & {
    as?: any;
} & { [key: string]: any };

const StyledButtonWithArrow = styled(Button)<{ isLoading?: boolean }>`
    justify-content: ${p => (p.isLoading ? 'center' : 'space-between')};
    align-items: center;
`;

const Loader = styled.span.attrs({
    children: (
        <>
            <div />
            <div />
            <div />
        </>
    ),
})`
    font-size: 16px;
    display: inline-block;
    position: relative;
    width: 1.25em;
    height: 1.25em;

    & div {
        font-size: inherit;
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 1em;
        height: 1em;
        margin: 0.125em;
        border: 0.125em solid #fff;
        border-radius: 50%;
        animation: loader 1.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }

    & div:nth-child(1) {
        animation-delay: -0.45s;
    }

    & div:nth-child(2) {
        animation-delay: -0.3s;
    }

    & div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export function ButtonWithArrow<T>({
    as,
    loading,
    disabled,
    children,
    ...rest
}: StyledButtonWithArrow<T>): JSX.Element {
    return (
        <StyledButtonWithArrow isLoading={loading} disabled={disabled || loading} as={as} {...rest} fixedWidth>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <span>{children}</span>
                    <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faArrowRight} />
                </>
            )}
        </StyledButtonWithArrow>
    );
}

export const IconButton = styled.button.attrs({
    type: 'button',
})`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;

    &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
    }
`;
