import React, { useCallback, useEffect, useState } from 'react';
import {
    confirmCookiesConsent,
    ACCEPTED,
    DECLINED,
    registerConsentConfirmation,
    haveInteractedWithConsent,
} from './cookies';
import Title from './typography/Title';
import Text from './typography/Text';
import { useI18n } from '../i18n/useI18n';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { constructPath } from '../i18n/util';
import { Link } from 'gatsby';
import { Button } from './Button';
import { DOMModal } from './modal/DOMModal';
import { colors } from '../styles/config';

const Container = styled.div`
    background: ${colors.white};
    padding: 1.5rem;
    border: 1px solid #fafafa;
    max-width: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin-top: 1rem;

    button:not(:last-child) {
        margin-right: 0.5rem;
    }
`;

export function CookieConsent(): JSX.Element | null {
    const [show, setShow] = useState(false);
    const { t, language } = useI18n();

    useEffect(() => {
        if (!haveInteractedWithConsent()) {
            setShow(true);
        }

        registerConsentConfirmation(() => {
            setShow(false);
        });
    }, []);

    const accept = useCallback(() => {
        confirmCookiesConsent(ACCEPTED);
    }, []);

    const decline = useCallback(() => {
        confirmCookiesConsent(DECLINED);
    }, []);

    const privacyPolicyLink = constructPath('privacy-policy', language);
    const onPrivacyPage =
        typeof window !== 'undefined' && window.location && window.location.pathname === privacyPolicyLink;

    if (!show || onPrivacyPage) return null;

    return (
        <DOMModal>
            <Container>
                <Title level={2}>{t('cookieConsent.title')}</Title>
                <Text>
                    <p>{t('cookieConsent.text')}</p>
                    <p>
                        <Trans i18nKey="cookieConsent.readMode" t={t}>
                            Read more about cookies and it's use in our
                            <Link to={privacyPolicyLink}>Privacy Policy</Link>.
                        </Trans>
                    </p>
                </Text>
                <Actions>
                    <Button variant="primary" onClick={accept}>
                        {t('cookieConsent.accept')}
                    </Button>
                    <Button variant="secondary" onClick={decline}>
                        {t('cookieConsent.decline')}
                    </Button>
                </Actions>
            </Container>
        </DOMModal>
    );
}
