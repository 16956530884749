import styled from 'styled-components';
import { colors } from '../styles/config';

const IconLink = styled.a`
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    will-change: color;
    transition: color 0.6s cubic-bezier(0.25, 1, 0.5, 1);

    &:not(:last-child) {
        margin-right: 0.5em;
    }

    &:hover,
    &:focus {
        color: ${colors.blue};
    }
`;

export default IconLink;
