import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Header from './Header';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import { AlternatePath } from '../i18n/AlternatePath';
import { SkipToContent } from './SkipToContent';
import { NotificationContainer } from './notification/Notification';
import { CookieConsent } from './CookieConsent';

export interface LayoutProps {
    location: any;
    language: string;
}

interface LayoutStaticQuery {
    datoCmsSite: {
        faviconMetaTags: any;
    };
    site: {
        siteMetadata: {
            siteURL: string;
        };
    };
}

const Layout: React.FC<LayoutProps> = props => {
    const { children, location, language } = props;
    const data = useStaticQuery<LayoutStaticQuery>(graphql`
        query LayoutQuery {
            datoCmsSite {
                faviconMetaTags {
                    ...GatsbyDatoCmsFaviconMetaTags
                }
            }
            site {
                siteMetadata {
                    siteURL
                }
            }
        }
    `);

    return (
        <React.Fragment>
            <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags}>
                <html lang={language} />
            </HelmetDatoCms>
            <AlternatePath siteUrl={data.site.siteMetadata.siteURL} />
            <GlobalStyles />
            <SkipToContent />
            <Header />
            {children}
            <Footer />
            <NotificationContainer />
            <CookieConsent />
        </React.Fragment>
    );
};
export default Layout;
