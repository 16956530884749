import React from 'react';
import styled, { AnyStyledComponent } from 'styled-components';
import { breakpoints, desktop, fonts, tablet } from '../../styles/config';

const applyBaseStyles = (c: AnyStyledComponent) => styled(c)`
    font-family: ${fonts.workSans}, sans-serif;
    font-weight: 300;
    line-height: 1.2em;
    margin-bottom: 0.5em;
`;

const h1 = styled.h1`
    font-size: 28px;
    ${tablet`
    font-size: 32px;
  `}
    ${desktop`
    font-size: 42px;
  `}
`;

const h2 = styled.h2`
    font-size: 28px;
    @media (min-width: ${breakpoints.tablet}px) {
        font-size: 32px;
    }
    @media (min-width: ${breakpoints.desktop}px) {
        font-size: 42px;
    }
`;

const h3 = styled.h3``;
const h4 = styled.h4``;
const h5 = styled.h5``;
const h6 = styled.h6``;

const headers = [h1, h2, h3, h4, h5, h6].map(applyBaseStyles);

export interface TitleProps {
    level?: 1 | 2 | 3 | 4 | 5 | 6;
    className?: string;
    style?: object;
}

export const Title: React.FC<TitleProps> = ({ level = 2, className, children, style = {} }) => {
    const Header = headers[level - 1];
    return (
        <Header style={style} className={className}>
            {children}
        </Header>
    );
};

export default Title;
