import React from 'react';
import Link from '../../i18n/Link';
import styled from 'styled-components';
import { breakpoints, colors, desktop, fonts, tablet } from '../../styles/config';
import mobileLogo from './mobile-logo.png';
import logo from './logo.png';
import { useI18n } from '../../i18n/useI18n';

const StyledLink = styled(Link)`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    color: ${colors.white};
    text-decoration: none;
    font-family: ${fonts.workSans}, sans-serif;
    font-size: 1.125rem;
    font-weight: 300;

    ${desktop`
    font-size: 1.375rem;
  `}

    img {
        height: 70px;
        width: auto;
        ${desktop`
        height: 92px;
    `}
    }
`;

const Name = styled.span`
    display: inline-block;
    @media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.laptop - 0.02}px) {
        display: none;
    }
`;

export const Logo: React.FC = () => {
    const { t } = useI18n();
    return (
        <StyledLink name="index" aria-label={t('nav.goto-home-page')}>
            <picture>
                <source srcSet={mobileLogo} media={`(max-width: ${breakpoints.laptop - 1}px)`} />
                <source srcSet={logo} media={`(min-width: ${breakpoints.laptop}px)`} />
                <img src={logo} alt="" />
            </picture>
            <Name>Marta Wilczyńska</Name>
        </StyledLink>
    );
};

export default Logo;
