import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { useWindowEvent } from './util/useWindowEvent';

let dialogCount = 0;

export interface DialogProps extends React.HTMLAttributes<HTMLDivElement> {
    focusPrevious?: boolean;
}

function tryFocus(el?: any): boolean {
    if (!el || !('focus' in el) || typeof el.focus !== 'function') return false;

    try {
        el.focus();
        return document.activeElement === el;
    } catch (e) {
        return false;
    }
}

function canFocus(el: any) {
    if (!el || !('focus' in el) || typeof el.focus !== 'function') return false;
    return el.tabIndex !== -1;
}

function focusFirst(el: Element): boolean {
    for (const child of Array.from(el.children)) {
        if (canFocus(child) && tryFocus(child)) return true;
        if (focusFirst(child)) return true;
    }
    return false;
}

function focusLast(el: Element): boolean {
    for (const child of Array.from(el.children).reverse()) {
        if (canFocus(child) && tryFocus(child)) return true;
        if (focusLast(child)) return true;
    }
    return false;
}

const FocusableNode = styled.div.attrs({ tabIndex: 0 })`
    position: fixed;
    top: -1000px;
    left: -1000px;
    width: 0;
    height: 0;
`;

interface DialogInnerState {
    lastFocusedElement: Element | null;
    previouslyFocusedElement: Element | null;
    ignoreEvent: boolean;
}

const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(({ children, focusPrevious, ...rest }, ref) => {
    const preNode = useRef<HTMLDivElement>(null);
    const postNode = useRef<HTMLDivElement>(null);
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const refCallback = useCallback((r: HTMLDivElement | null) => {
        dialogRef.current = r;
        if (ref && typeof ref === 'function') ref(r);
        if (ref && typeof ref === 'object') ref.current = r;
    }, []);

    const state = useRef<DialogInnerState>({
        previouslyFocusedElement: document.activeElement,
        lastFocusedElement: null,
        ignoreEvent: false,
    });

    useWindowEvent(
        'focus',
        (e: FocusEvent) => {
            if (state.current.ignoreEvent) return;

            state.current.ignoreEvent = true;
            if (dialogRef.current) {
                if (document.activeElement === preNode.current) focusLast(dialogRef.current);
                else if (document.activeElement === postNode.current) focusFirst(dialogRef.current);
                else if (!dialogRef.current.contains(document.activeElement)) {
                    focusFirst(dialogRef.current);
                    if (document.activeElement === state.current.lastFocusedElement) focusLast(dialogRef.current);
                    state.current.lastFocusedElement = document.activeElement;
                }
            }
            state.current.ignoreEvent = false;
        },
        true
    );

    useEffect(() => {
        dialogCount++;
        document.documentElement.classList.add('has-dialog');
        return () => {
            state.current.ignoreEvent = true;
            if (focusPrevious) tryFocus(state.current.previouslyFocusedElement);
            dialogCount--;
            if (dialogCount === 0) document.documentElement.classList.remove('has-dialog');
        };
    }, []);

    useLayoutEffect(() => {
        if (dialogRef.current) {
            state.current.ignoreEvent = true;
            tryFocus(dialogRef.current);
            state.current.ignoreEvent = false;
        }
    }, []);

    return (
        <React.Fragment>
            <FocusableNode ref={preNode} />
            <div tabIndex={-1} {...rest} ref={refCallback}>
                {children}
            </div>
            <FocusableNode ref={postNode} />
        </React.Fragment>
    );
});

Dialog.defaultProps = {
    role: 'dialog',
    tabIndex: -1,
    focusPrevious: true,
};

export default Dialog;
