import { useEffect } from 'react';

export function useWindowEvent<K extends keyof WindowEventMap>(
    event: K,
    callback: (this: Window, e: WindowEventMap[K]) => void,
    options?: boolean | AddEventListenerOptions
): void {
    useEffect(() => {
        window.addEventListener(event, callback, options);
        () => {
            window.removeEventListener(event, callback, options);
        };
    });
}
