// this is an identity function just to mark strings to be exported
const t = (v: any) => v;

export interface NavigationElement {
    translation: string;
    name: string;
    translationFragment?: string;
}

export const desktopNavigationElements: NavigationElement[] = [
    { translation: t('nav.about-me'), name: 'about' },
    { translation: t('nav.plastic-surgery'), name: 'plastic-surgery' },
    // { translation: t('nav.faq'), name: 'faq' },
    { translation: t('nav.reviews'), name: 'reviews' },
    { translation: t('nav.contact'), name: 'index', translationFragment: t('nav.contact') },
];

export const collapsibleNavigationElements: NavigationElement[] = [
    { translation: t('nav.about-me'), name: 'about' },
    { translation: t('nav.plastic-surgery'), name: 'plastic-surgery' },
    // { translation: t('nav.faq'), name: 'faq' },
    { translation: t('nav.reviews'), name: 'reviews' },
    { translation: t('nav.directions'), name: 'directions' },
    { translation: t('nav.pricing'), name: 'pricing' },
    { translation: t('nav.contact'), name: 'index', translationFragment: t('nav.contact') },
];
