import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faFacebookMessenger,
    faFacebookSquare,
    faInstagramSquare,
    faLinkedin,
    faTwitterSquare,
    faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import { ISocialMediaType } from '../../models/ISocialMedia';

export default function iconForType(type: ISocialMediaType): IconDefinition {
    switch (type) {
        case 'facebook':
            return faFacebookSquare;
        case 'messenger':
            return faFacebookMessenger;
        case 'instagram':
            return faInstagramSquare;
        case 'linkedin':
            return faLinkedin;
        case 'youtube':
            return faYoutubeSquare;
        case 'twitter':
            return faTwitterSquare;
    }
}
