import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { prop } from 'ramda';
import { useI18n } from '../../i18n/useI18n';
import { DatoCmsList } from '../../models/DatoCms';
import { SocialMediaDisplayContainer } from './SocialMediaDisplayContainer';
import { ISocialMedia } from '../../models/ISocialMedia';
import { SocialMediaLink } from './SocialMediaLink';

export interface SocialMediaDisplayProps {
    className?: string;
    isBig?: boolean;
}

const isInLocale = (language: string) => ({ locale }: ISocialMedia) => locale === language;

export const SocialMediaDisplay: React.FC<SocialMediaDisplayProps> = ({ className, isBig = false }) => {
    const { language } = useI18n();
    const socialMediaData = useStaticQuery<DatoCmsList<'allDatoCmsSocialMediaLink', ISocialMedia>>(graphql`
        query SocialMediaLink {
            allDatoCmsSocialMediaLink {
                edges {
                    node {
                        locale
                        url
                        socialMediaType
                        textToSpeech
                    }
                }
            }
        }
    `);

    const links = React.useMemo(
        () =>
            socialMediaData.allDatoCmsSocialMediaLink.edges
                .map(prop('node'))
                .filter(isInLocale(language))
                .map(({ url, socialMediaType, textToSpeech }) => {
                    return <SocialMediaLink key={url} url={url} type={socialMediaType} text={textToSpeech} />;
                }),
        [socialMediaData, language]
    );

    return (
        <SocialMediaDisplayContainer isBig={isBig} className={className}>
            {links}
        </SocialMediaDisplayContainer>
    );
};
