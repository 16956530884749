import React from 'react';
import ReactDOM from 'react-dom';
import canUseDom from '../util/canUseDom';
import Modal from './Modal';

export interface DOMModalProps {
    children: React.ReactNode;
}

export function DOMModal({ children }: DOMModalProps) {
    if (!canUseDom()) return null;
    const modal = <Modal>{children}</Modal>;
    return ReactDOM.createPortal(modal, window.document.body);
}
