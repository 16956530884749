import styled, { css } from 'styled-components';
import { colors } from '../styles/config';

export const TABLE_CSS = css`
    border-collapse: collapse;

    td,
    th {
        border-style: solid;
        border-width: 1px;
        border-color: ${colors.grey13} ${colors.grey60};
        text-align: left;
        padding: 0.5rem;
    }

    td {
        color: ${colors.grey80};
        font-weight: 300;
    }

    th {
        font-weight: 500;
    }

    tr:first-child td,
    tr:first-child th {
        border-top-color: transparent;
    }
    tr:last-child td,
    tr:last-child th {
        border-bottom-color: transparent;
    }
    tr td:first-child,
    tr th:first-child {
        border-left-color: transparent;
    }
    tr td:last-child,
    tr th:last-child {
        border-right-color: transparent;
    }
`;

const Table = styled.table`
    ${TABLE_CSS};
`;

export default Table;
