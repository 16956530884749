import React from 'react';
import { useI18n } from '../../i18n/useI18n';
import styled from 'styled-components';
import NavigationLink from './NavigationLink';

export interface LanguageSwitchProps {
    onSwitch: () => void;
}

const LABELS: Record<string, string> = {
    en: 'Swtich to English',
    pl: 'Zmień na polski',
};

const LanguageSwitchLink = styled(NavigationLink)`
    margin-top: auto;
`;

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ onSwitch }) => {
    const { language, languageUrls } = useI18n();
    const destinationLanguage = language === 'pl' ? 'en' : 'pl';
    const to = languageUrls[destinationLanguage];
    return (
        <LanguageSwitchLink onClick={onSwitch} to={to} lang={destinationLanguage} hrefLang={destinationLanguage}>
            {LABELS[destinationLanguage]}
        </LanguageSwitchLink>
    );
};

export default LanguageSwitch;
