import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { i18n, TFunction } from 'i18next';
import { I18nContext, I18nContextType } from './context';

export interface UseI18nReturn extends I18nContextType {
    t: TFunction;
    i18n: i18n;
}

export function useI18n(ns?: string): UseI18nReturn {
    const { t, i18n } = useTranslation(ns);
    const i18nContext = useContext(I18nContext);

    return {
        ...i18nContext,
        t,
        i18n,
    };
}
