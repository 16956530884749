import { createGlobalStyle } from 'styled-components';
import { colors, fonts } from './config';

export const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: 2px dashed #FFA500;
    outline-offset: 2px;
  }

  html, body {
    font-size: 16px;
    color: ${colors.grey80};
    background: ${colors.grey2};
    font-family: ${fonts.lato}, sans-serif;
  }

  html.has-dialog {
    overflow: hidden;
  }

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
  }
`;

export default GlobalStyles;
