import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../styles/config';
import { TABLE_CSS } from '../Table';
import useSanitizedHtml from '../util/useSanitizedHtml';

export interface TextProps extends Omit<HTMLAttributes<HTMLDivElement>, 'dangerouslySetInnerHTML'> {
    className?: string;
    html?: string;
    dangerouslySetSanitizedHTML?: string;
}

const ContentWrapper = styled.div`
    font-size: 16px;
    font-weight: 400;
    font-family: ${fonts.lato}, sans-serif;
    line-height: 1.5;

    h1 {
        font-size: 2.5rem;
        line-height: 1.1;
        margin-bottom: 1.5rem;
    }

    h2 {
        line-height: 1.1;
        font-size: 2rem;
        margin-bottom: 1.2rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${fonts.workSans}, sans-serif;
        &:not(:first-child) {
            margin-top: 2.5rem;
        }
    }

    p,
    table,
    ul,
    ol {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    ol,
    ul {
        padding-left: 1.5rem;
    }

    ul {
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    li {
        padding-left: 0.5rem;
        margin-bottom: 0.25rem;
    }

    strong,
    b {
        font-weight: bold;
    }

    table {
        ${TABLE_CSS};
    }

    a {
        cursor: pointer;

        &,
        &:visited {
            text-decoration: none;
            color: ${colors.blueDarker};
        }

        &:hover,
        &:focus {
            color: ${colors.blueDarker};
            text-decoration: underline;
        }
    }
`;

export const Text: React.FC<TextProps> = ({ className, html, dangerouslySetSanitizedHTML, children, ...rest }) => {
    const sanitizedHtml = useSanitizedHtml(html || '');

    const htmlContent = dangerouslySetSanitizedHTML || sanitizedHtml;
    if (htmlContent) {
        return <ContentWrapper {...rest} className={className} dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }

    return (
        <ContentWrapper {...rest} className={className}>
            {children}
        </ContentWrapper>
    );
};

export default Text;
