import styled from 'styled-components';

const Overlay = styled.div`
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10000;
`;

export default Overlay;
