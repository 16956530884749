import React from 'react';
import { Transition } from 'react-spring/renderprops';
import styled from 'styled-components';

const StyledBackdrop = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    will-change: opacity;
`;

export interface BackdropProps extends React.HTMLAttributes<HTMLDivElement> {
    show: boolean;
}

const Backdrop: React.FC<BackdropProps> = ({ show, style, ...rest }) => {
    style = style ?? {};
    return (
        <Transition items={show} from={{ opacity: 0 }} enter={{ opacity: 1 }} leave={{ opacity: 0 }}>
            {show => show && (props => <StyledBackdrop {...rest} style={{ ...style, ...props }} />)}
        </Transition>
    );
};

Backdrop.defaultProps = {
    show: false,
};

export default Backdrop;
